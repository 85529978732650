import React, { useState, useContext, useEffect, useMemo, ReactNode } from "react";
import classNames from "classnames";
import { LoadingIndicatorInline } from "../loading_indicator/index";

import * as styles from "./styles.module.scss";

import {
  fetchAmazonTnfByCategory,
  fetchTopEventByCategory
} from "utils/ad_engage";

import { MixPanelContext } from "../../context/mixpanel_context";
import { 
  TopEventCreativeMetadata,
  TopEventCreativeByCategoryMetadata
 } from "models/top_event_creative";
import { NewTopEventCreativeCard } from "../creative_card/index";
import { CategoryOverviewPageHeader, TopEventLanguageSelector } from "./CategoryOverview";
import {Link} from "gatsby";

export interface TopEventByCategoryOverviewProps {
  pageHeader?: ReactNode;
  pageFooter?: ReactNode;
}

export interface TopEventByCategoryOverviewProps {
  pageHeader?: ReactNode;
  pageFooter?: ReactNode;
  amazonTnfEnglish?: boolean;
  amazonTnfSpanish?: boolean;
  headerTitle?: string;
}

const TopEventByCategoryOverview: React.FC<(TopEventByCategoryOverviewProps)> = (props) => {
  const { pageHeader, pageFooter, amazonTnfEnglish, amazonTnfSpanish } = props;
  const [topAdsByCategory, setTopAdsByCategory] = useState<{ [key: string]: TopEventCreativeByCategoryMetadata }>(null);
  const [topAdsWeek, setTopAdsWeek] = useState<number>(null);
  const [contentLoading, setContentLoading] = useState<boolean>(true);
  const mixpanel = useContext(MixPanelContext);
  const [spanishTopAdsByCategory, setSpanishTopAdsByCategory] = useState<{ [key: string]: TopEventCreativeByCategoryMetadata }>(null);
  const [currentTopEventsLanguage, setCurrentTopEventsLanguage] = useState<String>("English Only");

  const currentAds = useMemo(() => {
    return currentTopEventsLanguage == "English Only" ? topAdsByCategory : spanishTopAdsByCategory;
  }, [topAdsByCategory, spanishTopAdsByCategory, currentTopEventsLanguage]);


  useEffect(() => {
    if (amazonTnfEnglish) {
      fetchAmazonTnfByCategory()
        .then((data) => { setTopAdsByCategory(data.data); setTopAdsWeek(null) })
        .catch((err) => { console.log(err) })
        .finally(() => { setContentLoading(false); });
      return;
    }

    if (amazonTnfSpanish) {
      fetchAmazonTnfByCategory(true)
        .then((data) => { setTopAdsByCategory(data.data); setTopAdsWeek(null) })
        .catch((err) => { console.log(err) })
        .finally(() => { setContentLoading(false); });
      return;
    }

    Promise.all([
      fetchTopEventByCategory()
      .then((data) => { setTopAdsByCategory(data.data); setTopAdsWeek(data.week) })
      .catch((err) => { console.log(err) }),
      fetchTopEventByCategory(true)
      .then((data) => { setSpanishTopAdsByCategory(data.data) })
      .catch((err) => { console.log(err) })
    ]).finally(() => { setContentLoading(false); });
  }, []);

  const renderContent = () => {
    if (contentLoading) {
      return (
        <div className={classNames("flex-fill-remainder", styles.loadingIndicatorContainer)}>
          <LoadingIndicatorInline width="64px" height="64px" />
        </div>
      )
    }

    return (
      <div className={classNames("flex-fill-remainder has-background-anti-flash-white full-height-and-width-minus-navbar-and-sidebar", styles.categoryOverview)}>
        <div className={classNames(styles.categoryOverviewHeader)}>
          <div className={classNames("flex-col", styles.categoryOverviewDisclaimer)}>
            <div className={styles.categoryOverviewDisclaimerText}>
              Only EDO can report the real-time TV performance of every brand advertising in the NFL - <span className={styles.categoryOverviewDisclaimerBoldText}>including Amazon Thursday Night Football.</span>
            </div >
            <div className={styles.categoryOverviewDisclaimerText}>
              When your ad is on the line, access to real-time customer engagement is the only thing that counts.
            </div>
          </div>

          {/*<div className={classNames("flex-col")}>*/}
          {/*  <div className={styles.categoryOverviewTitle}>*/}
          {/*    {`${headerTitle || TOP_EVENTS[CURRENT_TOP_EVENT_KEY].header} By Category`}*/}
          {/*  </div >*/}
          {/*  <div className={styles.categoryOverviewSubtitle}>*/}
          {/*    {`Sorted By ${TOP_EVENTS[CURRENT_TOP_EVENT_KEY].sort}`}*/}
          {/*  </div>*/}
          {/*</div>*/}


          <div className={classNames("flex-col", styles.nflWeekCol)}>
            { topAdsWeek && <div className={styles.nflWeekText}>{`NFL Week ${topAdsWeek || ""}`}</div> }
            <div className={styles.nflWeekText}>Data resets weekly on Friday</div>
            { (!amazonTnfSpanish && !amazonTnfEnglish) ? <div>
              <TopEventLanguageSelector setCurrentTopEventsLanguage={setCurrentTopEventsLanguage} />
            </div>
            : null
            }
          </div>
        </div >

        <div className={"flex-row flex-wrap"}>
          { currentAds && Object.keys(currentAds).map((key) => (
            <>
              { currentAds[key] && currentAds[key]["top_ads"] && currentAds[key]["top_ads"].length > 0 && (
                <div key={`${key}-container`} className={"flex-row flex-wrap"} style={{ margin: "10px 0", width: "100%" }}>
                  <div key={`${key}-category-name`} style={{ fontSize: 16, fontWeight: 600, marginBottom: 5 }}>
                    {currentAds[key]["name"]} <span style={{ opacity: 0.5, fontWeight: 'normal' }}>Top Performing Ads</span>
                  </div>
                  <div key={`${key}-category-see-more`} className={styles.seeMore}>
                    <Link to={"/contact-us"} >{"see more..."}
                    </Link>
                  </div>
                  <div key={`${key}-top-ads-container`} className={"flex-row flex-wrap"} style={{ width: "100%" }}>
                    {
                      currentAds[key] && currentAds[key]["top_ads"].map((entity: TopEventCreativeMetadata, i: number) => {
                        return <NewTopEventCreativeCard
                          creative_id={entity.creative_id}
                          brand_name={entity.brand_name}
                          creative_title={entity.creative_title}
                          category_name={entity.category_name}
                          tv_ad_airings_count={entity.airing_count}
                          thumbnail_url={entity.thumbnail_url}
                          brand_image_url={entity.brand_image_url}
                          duration={entity.duration}
                          key={`${i}-creative-card`}
                          rank={i + 1}
                        />;
                      })
                    }
                  </div>
                </div>
              )}
            </>

          )) }
        </div>
      </div >
    )
  }

  return (
    <>
      { pageHeader || <CategoryOverviewPageHeader /> }
      <div className={classNames("fill-parent", styles.categoryContent)}>
        <div className={classNames("flex-row", styles.contentWithFooter)}>
          {renderContent()}
        </div>
        { pageFooter }
      </div>
    </>
  );
};


export default TopEventByCategoryOverview;
